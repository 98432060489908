<template>
    <div>
        <b-card>
            <div class="row">
                <div class="col-12 col-md-3 text-center stake pt-4">
                    <img :src="staking.membership.image" alt="">
                    <h1>{{ staking.membership.name }}</h1>
                    <div class="row mt-4 ">
                        <div class="col-6 text-muted">
                            Start {{ formatDate(staking.initial_date) }}
                        </div>
                        <div class="col-6 text-muted">
                            ${{ staking.initial_tokens }} USD
                        </div>
                        <div class="col-6 text-muted mt-2">
                            Finish {{ formatDate(staking.final_date) }}
                        </div>
                        <!-- <div class="col-6 text-muted mt-2">
                            ${{ staking.final_tokens }} USD
                        </div> -->
                    </div>
                </div>
                <div class="col-12 col-md-9">
                    <Chart :id="staking.id" />
                </div>
            </div>
        </b-card>
    </div>
</template>
<script>
import Chart from './Chart.vue'
import moment from 'moment'
export default {
    props: ['staking'],
    components: {
        Chart
    },
    methods: {
        formatDate (date) {
            return moment(date).format('Y-MM-DD')
        }
    }
}
</script>
<style>
    .stake img{
        max-width: 50%;
        border-radius: 50%;
        width: 100px;
        height: 100px;
    }
</style>