<template>
    <div>
        <div class="text-right">
            <b-button variant="primary" class="btn-lg" :to="{ name: 'Create-Staking' }">
                Create staking
            </b-button>
        </div>
        <div class="mt-5" v-if="loading == false">
            <div v-for="staking in stakings" :key="staking.id" class="mb-4">
                <CardStaking :staking="staking" />
            </div>
        </div>
        <Loading v-else/>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import CardStaking from './CardStaking'
export default {
    components: {
        CardStaking
    },
    data () {
        return {
            loading: false
        }
    },
    created () {
        this.getData()
    },
    methods: {
        ...mapActions('staking', ['getStakings']),
        getData () {
            this.loading = true
            this.getStakings().then(() => {
                this.loading = false
            })
        }
    },
    computed: {
        ...mapState('staking', ['stakings'])
    }
}
</script>